import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { H6 } from '../../../components';

import css from './TransactionPanel.module.css';
import LineItemBasePriceMaybe from '../../../components/OrderBreakdown/LineItemBasePriceMaybe';
import LineItemProviderCommissionMaybe from '../../../components/OrderBreakdown/LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from '../../../components/OrderBreakdown/LineItemProviderCommissionRefundMaybe';
import LineItemCustomerCommissionMaybe from '../../../components/OrderBreakdown/LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from '../../../components/OrderBreakdown/LineItemCustomerCommissionRefundMaybe';
import LineItemSubTotalMaybe from '../../../components/OrderBreakdown/LineItemSubTotalMaybe';
import LineItemTotalPrice from '../../../components/OrderBreakdown/LineItemTotalPrice';
const { Money } = sdkTypes;

const _ = require('lodash');

// Functional component as a helper to build OrderBreakdown
const RemainingBreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    processName,
    lineItems,
    code,
    intl,
    transaction,
    isCustomer,
    isProvider,
    marketplaceName,
    userRole,
    currency,
    relatedTransactionAction,
    isPast = false,
    process,
    isEstimated = false
  } = props;

  let { relatedTransaction: relatedTransactionRaw } = props;
  let relatedTransaction = _.cloneDeep(relatedTransactionRaw);

  const classes = classNames(rootClassName || css.breakdownMaybe, className);

  let titleProcessName = isEstimated && !processName ? 'default-booking' : processName;

  let { advancePayment, hasAdvancePayment = true, hasFullAdvancePayment = false, isFirstPayment = true } = transaction?.attributes?.protectedData || props || {};

  if (hasAdvancePayment && !hasFullAdvancePayment && !isNaN(parseInt(advancePayment)) && advancePayment > 0 && advancePayment < 100) {
    let remainingPayment = 100 - parseInt(advancePayment);
    let advancePercentage = advancePayment / 100;
    let remainingPercentage = remainingPayment / 100;
    let clonedLineItems = _.cloneDeep(isPast ? relatedTransaction?.attributes?.lineItems : lineItems);

    let itemsPurchase = clonedLineItems
    if (isFirstPayment && !isPast) {
      itemsPurchase = clonedLineItems.map(item => {
        let originalAmount = item?.unitPrice?.amount || 0;
        let originalTotal = item?.lineTotal?.amount || 0;
        let amount = (originalAmount / advancePercentage) * remainingPercentage;
        let total = (originalTotal / advancePercentage) * remainingPercentage;

        item.unitPrice.amount = amount;
        item.lineTotal.amount = total;

        return item;
      });
    }

    let hasPayout = !!relatedTransaction?.attributes?.payoutTotal;
    if (isEstimated || (relatedTransaction?.attributes?.payinTotal !== undefined && !relatedTransaction?.attributes?.payinTotal)) {
      let payinTotal = new Money(itemsPurchase.reduce((acc, item) => acc + item.lineTotal.amount, 0), currency);
      relatedTransaction.attributes.payinTotal = payinTotal;
    }

    let preauthorizedState = process?.getTransitionsToStates([process.states.PREAUTHORIZED]) || [];
    let acceptedState = process?.getTransitionsToStates([process.states.ACCEPTED, process.states.TOTAL_ACCEPTED]) || [];
    let transactionToCheck = !isFirstPayment ? transaction : relatedTransaction;
    let pastTransitions = (transactionToCheck?.attributes?.transitions || []).map(transition => transition.transition);

    let secondTransactionIsPaid = pastTransitions.some(transition => preauthorizedState.includes(transition));
    let secondTransactionIsAccepted = pastTransitions.some(transition => acceptedState.includes(transition));

    let breakdownTitle = <><H6 as="h3" className={classNames(css.orderBreakdownTitle, css.noMargin, css.orderRemainingBreakdownTitle)}>
          <FormattedMessage id={`TransactionPanel.${titleProcessName}.orderRemainingBreakdownTitle`} />
        </H6>
        {!isEstimated ? <div className={css.descriptionText}><FormattedMessage id={`TransactionPanel.${userRole}.${secondTransactionIsPaid ? 'orderRemainingBreakdownPaidDescription' : 'orderRemainingBreakdownDescription'}`} /></div> : null}
        <hr className={classNames(css.totalDivider, css.noMarginTotalDiver)} />
      </>;

    return <div className={classes}>
        {isFirstPayment ? breakdownTitle : null}
        <LineItemBasePriceMaybe lineItems={itemsPurchase} code={code} intl={intl} />
        <LineItemSubTotalMaybe
          lineItems={itemsPurchase}
          code={code}
          userRole={userRole}
          intl={intl}
          marketplaceCurrency={currency}
        />
        <LineItemCustomerCommissionMaybe
          lineItems={itemsPurchase}
          isCustomer={isCustomer}
          marketplaceName={marketplaceName}
          intl={intl}
        />
        {/* <LineItemCustomerCommissionRefundMaybe
          lineItems={itemsPurchase}
          isCustomer={isCustomer}
          marketplaceName={marketplaceName}
          intl={intl}
        /> */}
        <LineItemProviderCommissionMaybe
          lineItems={itemsPurchase}
          isProvider={isProvider}
          marketplaceName={marketplaceName}
          intl={intl}
        />
        {/* <LineItemProviderCommissionRefundMaybe
          lineItems={itemsPurchase}
          isProvider={isProvider}
          marketplaceName={marketplaceName}
          intl={intl}
        /> */}

        {isCustomer || (isProvider && hasPayout) ? <LineItemTotalPrice transaction={relatedTransaction} isProvider={isProvider} intl={intl} /> : null}
        {relatedTransactionAction && ((isProvider && !secondTransactionIsAccepted && secondTransactionIsPaid) || (isCustomer && !secondTransactionIsPaid)) ? relatedTransactionAction : null}
        {!isFirstPayment ? breakdownTitle : null}
      </div>;
  }

  return null;
};

export default RemainingBreakdownMaybe;
