import queryString from 'query-string';
import { types as sdkTypes } from './sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

export const LISTING_PAGE_PENDING_APPROVAL_VARIANT = 'pending-approval';
export const LISTING_PAGE_DRAFT_VARIANT = 'draft';

export const LISTING_PAGE_PARAM_TYPE_NEW = 'new';
export const LISTING_PAGE_PARAM_TYPE_DRAFT = 'draft';
export const LISTING_PAGE_PARAM_TYPE_EDIT = 'edit';
export const LISTING_PAGE_PARAM_TYPES = [
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
];

export const prepareTransactionParams = (otherOrderParams, listing, bookingDates, firstPayment, forcePayment = false) => {
  const listingPublicData = listing?.attributes?.publicData;
  let advancePayment = parseInt(listingPublicData?.advancePayment || 0);
  let hasAdvancePayment = true;
  let hasFullAdvancePayment = false;

  if (advancePayment == 0) {
    hasAdvancePayment = false;
  }

  if (advancePayment == 100) {
    hasFullAdvancePayment = true;
  }

  let startDate = bookingDates.bookingStart;
  let endDate = bookingDates.bookingEnd;

  otherOrderParams.protectedData.bookingEnd = endDate.toISOString();
  otherOrderParams.protectedData.bookingStart = startDate.toISOString();
  otherOrderParams.protectedData.isFirstPayment = firstPayment;
  otherOrderParams.protectedData.seats = otherOrderParams?.seats || 1;

  otherOrderParams.protectedData.hasAdvancePayment = hasAdvancePayment;
  otherOrderParams.protectedData.hasFullAdvancePayment = hasFullAdvancePayment;

  if (forcePayment || firstPayment) {
    otherOrderParams.protectedData.advancePayment = advancePayment;
  }

  let startMonth = startDate.toLocaleString('en-US', { month: 'short' });
  let startDay = startDate.getDate();
  let endMonth = endDate.toLocaleString('en-US', { month: 'short' });
  let endDay = endDate.getDate();

  otherOrderParams.protectedData.formattedBookingDates = {
    startMonth,
    startDay,
    startDate: `${startMonth} ${startDay}`,
    endMonth,
    endDay,
    endDate: `${endMonth} ${endDay}`,
  };

  return otherOrderParams;
};
